import clsx from 'clsx'
import { PropsWithChildren, useCallback, useState } from 'react'
import styles from './styles/index.module.scss'

type TScrollableContainerWithBar = {
  showSlider?: boolean
  scrollBarSliderWidth?: number // number in percentage ex. 40
  snapScroll?: boolean
}
const SCROLL_BAR_SLIDER_WIDTH = 33
export const ScrollableContainerWithBar: React.FC<PropsWithChildren<TScrollableContainerWithBar>> = ({
  children,
  showSlider,
  scrollBarSliderWidth = SCROLL_BAR_SLIDER_WIDTH,
  snapScroll = true,
}) => {
  const [scrollPercentage, setScrollPercentage] = useState(0)

  const scrollHandler = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    const maxWidth = e.currentTarget.scrollWidth - e.currentTarget.clientWidth
    const currentWidth = e.currentTarget.scrollLeft
    setScrollPercentage((currentWidth * 100) / maxWidth)
  }, [])

  const calculatePostition = useCallback(() => {
    //100% - width of scroll-container (100% - scrollBarSliderWidth%) * scrollPercentage%
    const fullWidth = 100
    const leftPosition = (fullWidth - scrollBarSliderWidth) * (scrollPercentage / 100)
    return `${leftPosition}%`
  }, [scrollPercentage, scrollBarSliderWidth])

  return (
    <>
      <div
        className={clsx([
          styles['scroll-container'],
          'scroll-container',
          {
            'snap-scroll': snapScroll,
          },
        ])}
        onScroll={scrollHandler}
      >
        {children}
      </div>
      {showSlider && (
        <div className={clsx(styles['scroll-bar-container'], 'scroll-bar-container')}>
          <div
            className={styles['scroll-bar-element']}
            style={{ width: `${scrollBarSliderWidth}%`, left: `${calculatePostition()}` }}
          ></div>
        </div>
      )}
    </>
  )
}
