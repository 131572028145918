import { ScrollableContainerWithBar } from '@components/common/UI/ScrollableContainerWithBar'
import { getToLink, hasOverlay, isShoppableTeaser } from '@components/common/helpers/cms'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { IPlacement, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import clsx from 'clsx'
import React from 'react'
import { CmsFullBannerAnchor } from '../CmsFullBannerAnchor'
import { CmsMedia } from '../CmsMedia'
import { ImageCrops } from '../CmsMedia/types'
import { ShoppableTeaser } from '../ShoppableTeaser'
import { TextModule } from '../TextModule'
import styles from './styles/index.module.scss'

type TBoxWithFieldsBanner = {
  placement: IPlacement | ICMCollection
  placementCenter?: boolean
  teaserIndex?: number
  lazy?: boolean
  isFieldsOn?: boolean
  customerSegments: string[]
}

const MAX_ITEMS_COUNT = 4
const MIN_ITEMS_COUNT = 2

const imageCrops: { [key: string]: ImageCrops } = {
  '2_ITEMS': {
    xxl: {
      crop: 'landscape_ratio326x181',
      width: 1304,
      height: 724,
    },
    xl: {
      crop: 'landscape_ratio286x181',
      width: 1144,
      height: 724,
    },
    lg: {
      crop: 'landscape_ratio238x181',
      width: 857,
      height: 652,
    },
    md: {
      crop: 'landscape_ratio352x181',
      width: 1267,
      height: 652,
    },
    sm: {
      crop: 'portrait_ratio343x362',
      width: 617,
      height: 652,
    },
  },
  '3_ITEMS': {
    xxl: {
      crop: 'landscape_ratio216x181',
      width: 864,
      height: 724,
    },
    xl: {
      crop: 'landscape_ratio379x362',
      width: 758,
      height: 724,
    },
    lg: {
      crop: 'portrait_ratio315x362',
      width: 567,
      height: 652,
    },
    md: {
      crop: 'landscape_ratio352x181',
      width: 1267,
      height: 652,
    },
    sm: {
      crop: 'portrait_ratio327x362',
      width: 589,
      height: 652,
    },
  },
  '4_ITEMS': {
    xxl: {
      crop: 'landscape_ratio200x181',
      width: 800,
      height: 724,
    },
    xl: {
      crop: 'landscape_ratio200x181',
      width: 800,
      height: 724,
    },
    lg: {
      crop: 'landscape_ratio204x181',
      width: 734,
      height: 652,
    },
    md: {
      crop: 'landscape_ratio352x181',
      width: 1267,
      height: 652,
    },
    sm: {
      crop: 'portrait_ratio327x362',
      width: 589,
      height: 652,
    },
  },
}

const BoardWithFieldsBanner: React.FC<TBoxWithFieldsBanner> = ({
  placement,
  placementCenter,
  teaserIndex,
  lazy,
  isFieldsOn = false,
  customerSegments,
}) => {
  const items = 'items' in placement ? placement?.items.filter(isLXTeaser) : placement?.teasableItems.filter(isLXTeaser)
  const itemsCount = items?.length > MAX_ITEMS_COUNT ? MAX_ITEMS_COUNT : items?.length
  const crop = itemsCount >= MIN_ITEMS_COUNT ? imageCrops[`${itemsCount}_ITEMS`] : imageCrops['2_ITEMS']
  const prefix = isFieldsOn ? 'on' : 'below'
  const overrideTeaserOverlayTextAlign = placementCenter ? 'center' : undefined

  return (
    <div
      className={clsx(
        styles['board-with-fields'],
        styles[`board-with-fields-${prefix}`],
        styles[`board-with-fields-${itemsCount}`]
      )}
    >
      <ScrollableContainerWithBar showSlider>
        <div className={clsx(styles['board-content'])}>
          {items?.map((item, index) => {
            const isShoppable = isShoppableTeaser(item)

            return (
              <div key={item.id} className={clsx(styles['board-content-item'], item.teaserBackground)}>
                {item.media.length > 0 && (
                  <CmsFullBannerAnchor toLink={getToLink(item)} teaserIndex={teaserIndex} bannerIndex={index + 1}>
                    <CmsMedia media={item.media} imageCrops={crop} hasOverlay={hasOverlay(item)} lazy={lazy} />
                  </CmsFullBannerAnchor>
                )}
                {isShoppable && (
                  <ShoppableTeaser
                    hotZonesSettings={item?.hotZonesSettings}
                    hotZones={item?.hotZones}
                    customerSegments={customerSegments}
                  />
                )}
                <TextModule
                  teaser={item}
                  teaserIndex={teaserIndex}
                  overrideTeaserOverlayTextAlign={overrideTeaserOverlayTextAlign}
                />
              </div>
            )
          })}
        </div>
      </ScrollableContainerWithBar>
    </div>
  )
}

export default BoardWithFieldsBanner
